import { ClientInvitation, clientInvitationConverter, createInvitationInput } from '@maru44/huntre-utils/src/models/clientInvitation'
import { clientMemberConverter, ClientMemberRole } from '@maru44/huntre-utils/src/models/clientMember'
import { User } from '@maru44/huntre-utils/src/models/user'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { addDoc, and, collection, getDocs, query, where } from 'firebase/firestore'
import { useCallback } from 'react'
import { DuplicateError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'
import useSWR from '../useSWR'

export const useClientInvitations = (clientId: string) => {
  const createInvitation = useCallback(
    async (email: string, role: ClientMemberRole, group: { id: string; name: string }, from: User) => {
      const col = collection(firestore, `clients/${clientId}/clientMembers`)
      const q = query(col.withConverter(clientMemberConverter), where('email', '==', email))
      const refs = await getDocs(q)
      if (refs.size > 0 || refs.docs.length > 0) {
        throw new DuplicateError('clientMember', { key: 'email', value: email })
      }

      const now = new Date()
      {
        const input = createInvitationInput(email, group, from, role)
        const col = collection(firestore, `clients/${clientId}/clientInvitations`)
        const q = query(
          col.withConverter(clientInvitationConverter),
          and(where('email', '==', email), where('acceptedAt', '==', null), where('expiresAt', '>=', now), where('deniedAt', '==', null))
        )
        const refs = await getDocs(q)
        if (refs.size > 0 || refs.docs.length > 0) {
          throw new DuplicateError('clientInvitation', { key: 'email', value: email })
        }

        await addDoc(col, input)
      }
    },
    [clientId]
  )

  const listClientInvitations = useSWR(
    [`/clients/${clientId}/clientInvitations`, clientId],
    async () => {
      const col = collection(firestore, `clients/${clientId}/clientInvitations`)
      const now = new Date()
      const q = query(col, and(where('acceptedAt', '==', null), where('deniedAt', '==', null), where('expiresAt', '>=', now)))

      const snap = await getDocs(q)

      return snap.docs.map((v) => getData<ClientInvitation>(v))
    },
    { disabled: !clientId }
  )

  return { createInvitation, listClientInvitations }
}
