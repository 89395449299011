import { Client } from '@maru44/huntre-utils/src/models/client'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { firestore } from 'src/utils/firebase'
import useSWR from '../useSWR'

export const useClients = (userId: string) => {
  const listClients = useSWR(
    [`/clients`, userId],
    async () => {
      const q = query(collection(firestore, `clients`), where('clientMemberIds', 'array-contains', userId))
      const snap = await getDocs(q)

      const clients = snap.docs.map((s) => getData<Client>(s))
      return clients
    },
    {
      disabled: !userId,
    }
  )

  return { listClients }
}
