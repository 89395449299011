import { ClientDevice, clientDeviceConverter } from '@maru44/huntre-utils/src/models/clientDevice'
import { Device, DevicePosition } from '@maru44/huntre-utils/src/models/device'
import { SimApplication } from '@maru44/huntre-utils/src/models/simApplication'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { collectionGroup, deleteDoc, doc, getDoc, getDocs, query, serverTimestamp, updateDoc, where } from 'firebase/firestore'
import { useCallback } from 'react'
import { NotFoundError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'
import useSWR from '../useSWR'

export const useClientDevice = (id: string, clientId: string) => {
  const getClientDeviceWithDevice = useSWR(
    [`/clients/${clientId}/clientDevices/${id}`, clientId, id],
    async () => {
      const cdRef = doc(firestore, `clients/${clientId}/clientDevices/${id}`)
      const snap = await getDoc(cdRef)
      const cd = getData<ClientDevice>(snap)

      if (!cd) {
        throw new NotFoundError('clientDevice', id)
      }

      const dRef = doc(firestore, `devices/${cd.deviceId}`)
      const dSnap = await getDoc(dRef)
      const d = getData<Device>(dSnap)

      if (!d) {
        throw new NotFoundError('device', cd.deviceId)
      }

      const q = query(collectionGroup(firestore, `simApplications`), where('deviceId', '==', id), where('status', '==', 'active'))
      const applicationSnap = await getDocs(q)

      const simApplication = applicationSnap.docs.length === 0 ? null : getData<SimApplication>(applicationSnap.docs[0])

      return {
        device: d,
        clientDevice: cd,
        simApplication: simApplication,
      }
    },
    {
      disabled: !id || !clientId,
    }
  )

  // // 登録日時以降だけにする
  // const listMessages = useSWR(
  //   [`/devices/${id}/deviceMessages`, id],
  //   async (registeredAt: Timestamp) => {
  //     const q = query(
  //       collection(firestore, `devices/${id}/deviceMessages`),
  //       where('createdAt', '>=', registeredAt),
  //       orderBy('createdAt', 'desc'),
  //       limit(20)
  //     ).withConverter(deviceMessageConverter)

  //     const snap = await getDocs(q)
  //     return snap.docs.map((v) => v.data())
  //   },
  //   {
  //     disabled: !id,
  //   }
  // )

  const deleteClientDevice = useCallback(async () => {
    const ref = doc(firestore, `clients/${clientId}/clientDevices/${id}`)

    await deleteDoc(ref)
  }, [id, clientId])

  const updateIsTurnedOff = useCallback(
    async (isTurnedOff: boolean) => {
      const ref = doc(firestore, `clients/${clientId}/clientDevices/${id}`).withConverter(clientDeviceConverter)
      await updateDoc(ref, { isTurnedOff: isTurnedOff, updatedAt: serverTimestamp() })
    },
    [id, clientId]
  )

  const updatePosition = useCallback(
    async (position: DevicePosition | null, isInputPositionUsed?: boolean) => {
      const ref = doc(firestore, `clients/${clientId}/clientDevices/${id}`).withConverter(clientDeviceConverter)
      if (!position) {
        await updateDoc(ref, { position, isInputPositionUsed: false, positionUpdatedAt: null, updatedAt: serverTimestamp() })
      }
      await updateDoc(ref, {
        position,
        updatedAt: serverTimestamp(),
        positionUpdatedAt: serverTimestamp(),
        ...(isInputPositionUsed !== undefined ? { isInputPositionUsed } : {}),
      })
    },
    [id, clientId]
  )

  // 入力された位置情報と取得した位置情報のどちらを使うかを変える
  const updatePositionUse = useCallback(
    async (isInputPositionUsed: boolean) => {
      const ref = doc(firestore, `clients/${clientId}/clientDevices/${id}`).withConverter(clientDeviceConverter)
      await updateDoc(ref, { isInputPositionUsed, updatedAt: serverTimestamp() })
    },
    [id, clientId]
  )

  const updateMemoIdForNotification = useCallback(
    async (clientDeviceMemoId: string | undefined) => {
      const ref = doc(firestore, `clients/${clientId}/clientDevices/${id}`).withConverter(clientDeviceConverter)
      await updateDoc(ref, { memoIdForNotification: clientDeviceMemoId ?? null, updatedAt: serverTimestamp() })
    },
    [id, clientId]
  )

  return {
    getClientDeviceWithDevice,
    deleteClientDevice,
    updateIsTurnedOff,
    updatePosition,
    updatePositionUse,
    updateMemoIdForNotification,
  }
}
