import { zodResolver } from '@hookform/resolvers/zod'
import { Client, ClientType } from '@maru44/huntre-utils/src/models/client'
import { ClientMember, ClientMemberNotificationMethod } from '@maru44/huntre-utils/src/models/clientMember'
import { Box, Button, InputLabel, Stack, Switch, TextField, Typography } from '@mui/material'
import { FC, FormEvent, useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { FileInputArea } from 'src/components/atoms/FileInputArea'
import { useClient } from 'src/hooks/client/useClient'
import { useClientMember } from 'src/hooks/client/useClientMember'
import { DefaultBucketDir, useStorage } from 'src/hooks/storage/useStorage'
import { capture } from 'src/utils/sentry'
import { z } from 'zod'

type Props = {
  client: Client
  clientMember: ClientMember
  onSubmit: () => void
  selectClient: (clientId: string) => Promise<void>
}

const schema = z.object({
  name: z.string().min(1),
  iconURL: z.string(),
  phone: z.string(),
  notificationMethods: z.array(z.nativeEnum(ClientMemberNotificationMethod)),
})
// NOTE: notificationMethodsを変更したときに正しく動かない
// .refine(
//   ({ phone, notificationMethods }) => {
//     return !(!phone && notificationMethods.includes('line'))
//   },
//   {
//     message: LINE_PHONE_ERROR_MESSAGE,
//     path: ['phone'],
//   }
// )
type FormState = z.infer<typeof schema>

export const UpdateForm: FC<Props> = ({ client, clientMember, onSubmit, selectClient }) => {
  const [image, setImage] = useState<File | null>(null)

  const defaultValues = useMemo(() => {
    return {
      name: clientMember.name,
      iconURL: clientMember.iconURL,
      phone: clientMember.phone,
      notificationMethods: clientMember.notificationMethods,
    }
  }, [clientMember])

  const { control, handleSubmit, formState, setValue, watch } = useForm<FormState>({
    resolver: zodResolver(schema),
    defaultValues,
    mode: 'all',
  })

  const { uploadFile, getFileURL } = useStorage()

  const { updateClientMemberBySelf } = useClientMember(client?.id ?? '', clientMember.id)
  const { updateClient } = useClient(client?.id ?? '')

  const handleUpdate = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      try {
        if (image) {
          const result = await uploadFile(DefaultBucketDir.Public, image)
          await getFileURL(result.metadata.fullPath, (v) => setValue('iconURL', v))
        }
        await handleSubmit(async (input) => {
          await updateClientMemberBySelf(input)
          // clientがuserならclientの名前とiconURLをupdate
          if (client.type === ClientType.User) {
            const { id, ...rest } = client
            await updateClient({
              ...rest,
              name: input.name,
              iconURL: input.iconURL,
            })
          }
        })()
        toast.success('プロフィール設定を変更しました')
        // clientのリロード
        await selectClient(client.id)
        onSubmit()
      } catch (e) {
        capture(e)
        toast.error('プロフィール設定の変更に失敗しました')
      }
    },
    [client, clientMember.id, image, handleSubmit, setValue, uploadFile, onSubmit, selectClient, updateClient]
  )

  const current = watch()

  const methodsRemoved = useCallback(
    (method: ClientMemberNotificationMethod) => {
      const out = current.notificationMethods
      out.splice(out.indexOf(method), 1)
      return out
    },
    [current]
  )

  return (
    <Stack spacing={4} component="form" onSubmit={handleUpdate}>
      <Controller
        name="name"
        control={control}
        defaultValue={defaultValues.name}
        render={({ field }) => (
          <>
            <InputLabel required>表示名</InputLabel>
            <TextField required fullWidth type="text" {...field} error={!!formState.errors?.name} helperText={formState.errors?.name?.message} />
          </>
        )}
      />
      <Controller
        name="phone"
        control={control}
        defaultValue={defaultValues.phone}
        render={({ field }) => (
          <>
            <InputLabel>電話番号</InputLabel>
            <TextField fullWidth type="tel" {...field} error={!!formState.errors?.phone} helperText={formState.errors?.phone?.message} />
          </>
        )}
      />
      <>
        <InputLabel>アイコン</InputLabel>
        <FileInputArea onSetFile={setImage} defaultSrc={clientMember.iconURL} accept="image/*" />
      </>
      <>
        <InputLabel>通知方法</InputLabel>
        <Stack spacing={2} ml={2}>
          <Typography>
            <b>メール</b>
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography>off</Typography>
            <Switch
              checked={current.notificationMethods.includes('email')}
              onChange={(e) =>
                setValue(
                  'notificationMethods',
                  e.target.checked ? ([...new Set([...current.notificationMethods, 'email'])] as ClientMemberNotificationMethod[]) : methodsRemoved('email')
                )
              }
            />
            <Typography>on</Typography>
          </Box>
          <Typography>
            <b>LINE</b>
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography>off</Typography>
            <Switch
              checked={current.notificationMethods.includes('line')}
              onChange={(e) =>
                setValue(
                  'notificationMethods',
                  e.target.checked ? ([...new Set([...current.notificationMethods, 'line'])] as ClientMemberNotificationMethod[]) : methodsRemoved('line')
                )
              }
            />
            <Typography>on</Typography>
          </Box>
        </Stack>
        {formState.errors?.notificationMethods?.message && <Typography color="tomato">{formState.errors?.notificationMethods?.message}</Typography>}
      </>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Button variant="outlined" onClick={onSubmit}>
          キャンセル
        </Button>
        <Button variant="contained" type="submit" disabled={formState.isSubmitting || !formState.isValid}>
          更新する
        </Button>
      </Box>
    </Stack>
  )
}
