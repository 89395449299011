import { doc, getDoc, runTransaction, serverTimestamp, updateDoc } from '@firebase/firestore'
import { ClientInvitation, clientInvitationConverter } from '@maru44/huntre-utils/src/models/clientInvitation'
import { createClientMemberFromAuthUser } from '@maru44/huntre-utils/src/models/clientMember'
import { User as FirebaseUser } from 'firebase/auth'
import { useCallback } from 'react'
import { DuplicateError, NotFoundError, ValidationError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'

export const useInvitation = (invitation: ClientInvitation | undefined) => {
  // NOTE userをFirebaseUserじゃなくしたい
  const acceptInvitation = useCallback(async (user: FirebaseUser) => {
    if (!invitation) {
      throw new Error('invitation is required')
    }
    const ref = doc(firestore, `clients/${invitation.group.id}/clientInvitations/${invitation.id}`)
    const snap = await getDoc(ref.withConverter(clientInvitationConverter))

    if (!snap.exists()) {
      throw new NotFoundError('clientInvitation', invitation.id)
    }
    const data = snap.data()
    if (!!data.acceptedAt || !!data.deniedAt) {
      throw new ValidationError('invalid invitation')
    }

    await runTransaction(firestore, async (tx) => {
      const now = serverTimestamp()
      tx.update(ref, {
        updatedAt: now,
        acceptedAt: now,
      })

      // メンバードキュメントの作成
      // ここもfirestoreFunctionsに入れちゃって良いかも
      const memberRef = doc(firestore, `clients/${invitation.group.id}/clientMembers/${user.uid}`)
      const memberSnap = await getDoc(memberRef)
      if (memberSnap.exists()) {
        throw new DuplicateError('clientMember', { key: 'userId', value: user.uid })
      }
      tx.set(memberRef, createClientMemberFromAuthUser(user, invitation.role))
    })
  }, [])

  const denyInvitation = useCallback(async () => {
    if (!invitation) {
      throw new Error('invitation is required')
    }
    const ref = doc(firestore, `clients/${invitation.group.id}/clientInvitations/${invitation.id}`)
    const snap = await getDoc(ref.withConverter(clientInvitationConverter))

    if (!snap.exists()) {
      throw new NotFoundError('clientInvitation', invitation.id)
    }
    const data = snap.data()
    if (!!data.acceptedAt || !!data.deniedAt) {
      throw new ValidationError('invalid invitation')
    }

    const now = serverTimestamp()
    await updateDoc(ref, {
      updatedAt: now,
      deniedAt: now,
    })
  }, [])

  return { acceptInvitation, denyInvitation }
}
