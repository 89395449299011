import { SimPlan } from '@maru44/huntre-utils/src/models/simPlan'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { firestore } from 'src/utils/firebase'
import useSWR from '../useSWR'

export const useSimPlans = () => {
  const listSimPlans = useSWR('/simPlans', async () => {
    const q = query(collection(firestore, `simPlans`), where('isActive', '==', true))
    const snap = await getDocs(q)

    return snap.docs.map((s) => getData<SimPlan>(s))
  })

  return { listSimPlans }
}
