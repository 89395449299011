import { ClientDeviceWithDevice } from '@maru44/huntre-utils/src/models/clientDevice'
import { ClientMemberRole } from '@maru44/huntre-utils/src/models/clientMember'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useCallback, useContext, useMemo, useState } from 'react'
import { Breads } from 'src/components/atoms/BreadCrumbs'
import { CreateClientDeviceDialog } from 'src/components/dialogs/device/CreateClientDeviceDialog'
import { ClientContext } from 'src/components/providers/ClientProvider'
import { useClientDevices } from 'src/hooks/clientDevice/useClientDevices'
import { ClientDeviceTable } from 'src/pages/ClientDevices/DeviceTable'
import { ErrorPage } from '../ErrorPage'
import { DevicePositionsMap } from './DevicePositionsMap'

export const ClientDevices = () => {
  const { client, currentMember } = useContext(ClientContext)

  const { listClientDevicesWithDevice, createClientDevice, updateIsTurnedOff } = useClientDevices(client?.id ?? '')

  const [openCreate, setOpenCreate] = useState(false)

  const handleCreateClientDevice = useCallback(
    async ({ deviceId }: { deviceId: string }) => {
      await createClientDevice({
        deviceId,
      })
    },
    [createClientDevice, client?.id]
  )

  const positions = useMemo((): ClientDeviceWithDevice[] => {
    if (!listClientDevicesWithDevice.data) {
      return []
    }
    return listClientDevicesWithDevice.data.filter((v) => !!v.device.position || !!v.clientDevice.position)
  }, [listClientDevicesWithDevice.data])

  return (
    <>
      <Box>
        <Breads breadIds={['home']} />
        <Stack spacing={2}>
          <Typography variant="h5">デバイス一覧</Typography>
          <Typography>登録したデバイス一覧です</Typography>
          {listClientDevicesWithDevice.data && (
            <>
              <ClientDeviceTable
                clientDevices={listClientDevicesWithDevice.data}
                currentMember={currentMember}
                onUpdateIsTurnedOff={updateIsTurnedOff}
                mutate={listClientDevicesWithDevice.mutate}
              />
              {currentMember?.role === ClientMemberRole.Admin && (
                <Box display="flex" justifyContent="end">
                  <Button variant="contained" onClick={() => setOpenCreate(true)}>
                    デバイスの登録
                  </Button>
                </Box>
              )}
              {positions.length > 0 && (
                <Box>
                  <Typography variant="h6">位置情報</Typography>
                  <DevicePositionsMap devices={positions} />
                </Box>
              )}
            </>
          )}
          {listClientDevicesWithDevice.error && <ErrorPage exception={listClientDevicesWithDevice.error} />}
        </Stack>
      </Box>
      <CreateClientDeviceDialog
        open={openCreate}
        onClose={() => setOpenCreate(false)}
        listMutate={listClientDevicesWithDevice.mutate}
        onSubmit={handleCreateClientDevice}
      />
    </>
  )
}
