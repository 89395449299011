import { useParams } from 'react-router-dom'
import { LoadingLayout } from 'src/components/layouts/LoadingLayout'
import { useDevice } from 'src/hooks/device/useDevice'
import { Register } from 'src/pages/RegisterClientDevice/Register'
import { ValidationError } from 'src/utils/error'
import { ErrorPage } from '../ErrorPage'

export const RegisterClientDevice = () => {
  const { id } = useParams()
  const { getDevice } = useDevice(id ?? '')

  if (!id) {
    return <ErrorPage exception={new ValidationError('id required')} />
  }
  if (getDevice.isLoading) {
    return <LoadingLayout />
  }

  if (getDevice.error) {
    return <ErrorPage exception={getDevice.error} />
  }

  return <Register id={id} />
}
