import { doc, getDoc } from '@firebase/firestore'
import { InvitationCode } from '@maru44/huntre-utils/src/models/invitationCode'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { NotFoundError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'
import useSWR from '../useSWR'

export const useInvitationCode = (id: string) => {
  const getInvitationCode = useSWR(
    [`/invitationCodes/${id}`, id],
    async () => {
      const snap = await getDoc(doc(firestore, `invitationCodes/${id}`))

      if (!snap.exists()) {
        throw new NotFoundError('invitationCode', id ?? undefined)
      }

      return getData<InvitationCode>(snap)
    },
    {
      disabled: !id,
    }
  )

  return { getInvitationCode }
}
