import { ClientMember, clientMemberConverter, ClientMemberNotificationMethod, ClientMemberRole } from '@maru44/huntre-utils/src/models/clientMember'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { deleteDoc, doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { useCallback } from 'react'
import { NotFoundError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'
import useSWR from '../useSWR'

export const useClientMember = (clientId: string, id: string) => {
  const getClientMember = useSWR(
    [`/clients/${clientId}/clientMembers/${id}`, clientId, id],
    async () => {
      const ref = doc(firestore, `clients/${clientId}/clientMembers`, id)
      const snap = await getDoc(ref)
      if (!snap.exists) {
        throw new NotFoundError('clientMember', id)
      }
      return getData<ClientMember>(snap)
    },
    {
      disabled: !clientId || !id,
    }
  )

  const updateClientMemberByAdmin = useCallback(
    async (input: { role: ClientMemberRole; notificationMethods: ClientMemberNotificationMethod[] }) => {
      const ref = doc(firestore, `clients/${clientId}/clientMembers/${id}`).withConverter(clientMemberConverter)
      const snap = await getDoc(ref)
      if (!snap.exists) {
        throw new NotFoundError('clientMember', id)
      }
      const data = snap.data()
      if (!data) {
        throw new NotFoundError('clientMember', id)
      }
      await updateDoc(ref, {
        ...input,
        updatedAt: serverTimestamp(),
      })
    },
    [clientId, id]
  )

  const updateClientMemberBySelf = useCallback(
    async (input: { name: string; iconURL: string; phone: string; notificationMethods: ClientMemberNotificationMethod[] }) => {
      const ref = doc(firestore, `clients/${clientId}/clientMembers/${id}`).withConverter(clientMemberConverter)
      const snap = await getDoc(ref)
      if (!snap.exists) {
        throw new NotFoundError('clientMember', id)
      }
      const data = snap.data()
      if (!data) {
        throw new NotFoundError('clientMember', id)
      }
      await updateDoc(ref, {
        ...input,
        updatedAt: serverTimestamp(),
      })
    },
    [clientId, id]
  )

  //

  const deleteClientMember = useCallback(
    async (id: string) => {
      const ref = doc(firestore, `clients/${clientId}/clientMembers/${id}`).withConverter(clientMemberConverter)
      const snap = await getDoc(ref)
      if (!snap.exists) {
        throw new NotFoundError('clientMember', id)
      }
      const data = snap.data()
      if (!data) {
        throw new NotFoundError('clientMember', id)
      }
      await deleteDoc(ref)
    },
    [clientId, id]
  )

  return {
    getClientMember,
    updateClientMemberByAdmin,
    updateClientMemberBySelf,
    deleteClientMember,
  }
}
