import { Client } from '@maru44/huntre-utils/src/models/client'
import { ClientMember } from '@maru44/huntre-utils/src/models/clientMember'
import { ClientShippingAddress } from '@maru44/huntre-utils/src/models/clientShippingAddress'
import { configureScope } from '@sentry/react'
import { serverTimestamp, updateDoc } from 'firebase/firestore'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { useClientWithId } from 'src/hooks/background/useClient'
import { useClientShippingAddress } from 'src/hooks/background/useClientShippingAddress'
import { useUser } from 'src/hooks/background/useUser'
import { NotAccessibleClientError } from 'src/utils/error'
import { AuthContext } from './AuthProvider'

type ClientState = {
  client: Client | undefined
  currentMember: ClientMember | undefined
  clientMembers: ClientMember[]
  clientShippingAddresses: ClientShippingAddress[]
  isLoading: boolean
  error: Error | undefined
  selectClient: (clientId: string) => Promise<void>
}

export const ClientContext = createContext<ClientState>({
  client: undefined,
  currentMember: undefined,
  clientMembers: [],
  clientShippingAddresses: [],
  isLoading: false,
  error: undefined,
  selectClient: async (clientId: string) => {},
})

export function ClientProvider() {
  const [client, setClient] = useState<Client | undefined>()
  const [currentMember, setCurrentMember] = useState<ClientMember | undefined>()
  const [clientMembers, setClientMembers] = useState<ClientMember[]>([])
  const [clientShippingAddresses, setClientShippingAddresses] = useState<ClientShippingAddress[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | undefined>()
  const [user, isLoading] = useContext(AuthContext)
  // const navigate = useNavigate()

  const { getClient, listClientMembers } = useClientWithId()
  const { getUser } = useUser()
  const { listShippingAddresses } = useClientShippingAddress()

  const params = useParams()
  const selectClient = useCallback(
    async (clientId: string) => {
      setLoading(true)
      try {
        if (!user) {
          return
        }
        const fUser = await getUser(user.uid)
        if (!fUser) {
          setError(new Error('user not found'))
        }

        // set sentry scope
        configureScope((s) => s.setUser({ id: user.uid, email: user.email ?? undefined, username: user.displayName ?? undefined }))

        const client = await getClient(clientId)
        await updateDoc(fUser.ref, { selectedClient: clientId, updatedAt: serverTimestamp() })
        setClient(client ?? undefined)
        configureScope((s) => s.setExtra('clientId', client.id))

        const members = await listClientMembers(clientId)
        setClientMembers(members)
        setCurrentMember(members.find((v) => v.id === user.uid))

        const addresses = await listShippingAddresses(clientId)
        setClientShippingAddresses(addresses)
      } catch (e) {
        if (e instanceof Error) {
          setError(e)
        } else {
          setError(new Error('unkonwn error with fetch client'))
        }
      } finally {
        setLoading(false)
      }
    },
    [user, setLoading, getClient, setError, getUser, listClientMembers, setClientMembers, listShippingAddresses, setClientShippingAddresses, setCurrentMember]
  )

  useEffect(() => {
    const fetchClient = async () => {
      if (isLoading) {
        return
      }
      // ログインしてない
      if (!user) {
        setLoading(false)
        return
      }

      try {
        const fUser = await getUser(user.uid)

        // set sentry scope
        configureScope((s) => s.setUser({ id: user.uid, email: user.email ?? undefined, username: user.displayName ?? undefined }))

        const clientId = params.clientId ?? fUser?.data?.selectedClient ?? ''
        const client = await getClient(clientId)
        const members = await listClientMembers(clientId)
        const current = members.find((v) => v.id === user.uid)
        setCurrentMember(current ?? undefined)

        // clientに所属してなかったら 404
        if (!current) {
          setLoading(false)
          setError(new NotAccessibleClientError('the member not in client', clientId))
          return
        }

        await updateDoc(fUser.ref, { selectedClient: clientId, updatedAt: serverTimestamp() })
        setClient(client)
        configureScope((s) => s.setExtra('clientId', clientId))

        setClientMembers(members)

        const addresses = await listShippingAddresses(clientId)
        setClientShippingAddresses(addresses)
      } catch (e) {
        if (e instanceof Error) {
          setError(e)
        } else {
          setError(new Error('unkonwn error with fetch client'))
        }
      } finally {
        setLoading(false)
      }
    }

    fetchClient()
  }, [
    user,
    isLoading,
    params.clientId,
    setLoading,
    getClient,
    getUser,
    setClient,
    setError,
    listClientMembers,
    setClientMembers,
    listShippingAddresses,
    setClientShippingAddresses,
    setCurrentMember,
  ])

  const ctx = useMemo(
    () => ({
      client,
      currentMember,
      clientMembers,
      clientShippingAddresses,
      isLoading: loading,
      error,
      selectClient,
    }),
    [client, clientMembers, clientShippingAddresses, loading, error, selectClient]
  )

  return (
    <ClientContext.Provider value={ctx}>
      <Outlet />
    </ClientContext.Provider>
  )
}
