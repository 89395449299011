import { ClientInquiryReply, textClientInquiryKind } from '@maru44/huntre-utils/src/models/clientInquiry'
import { ClientMember } from '@maru44/huntre-utils/src/models/clientMember'
import { Avatar, Box, Paper, Stack, Typography } from '@mui/material'
import { useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Breads } from 'src/components/atoms/BreadCrumbs'
import { AuthContext } from 'src/components/providers/AuthProvider'
import { ClientContext } from 'src/components/providers/ClientProvider'
import { baseURL } from 'src/config'
import { useClientInquiry } from 'src/hooks/clientInquiry/useClientInquiry'
import { formatTimestamp } from 'src/utils/firebase'
import { ErrorPage } from '../ErrorPage'
import { ReplyForm } from './ReplyForm'

export const ClientInquiryPage = () => {
  const { id } = useParams()
  const [user] = useContext(AuthContext)
  const { client, clientMembers } = useContext(ClientContext)

  const { getInquiry, listClientInquiryReplies, createClientInquiryReply, updateInquiryResolved } = useClientInquiry(client?.id ?? '', id ?? '')

  const sender = useMemo(() => {
    return clientMembers.find((v) => v.id === getInquiry.data?.userId)
  }, [clientMembers, getInquiry.data])

  return (
    <>
      <Breads breadIds={['inquiries', 'inquiry']} />
      <Typography variant="h5">お問合せ詳細</Typography>
      <Stack spacing={2} mt={2}>
        {getInquiry.data && (
          <>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">{getInquiry.data.title ?? textClientInquiryKind(getInquiry.data.kind)}</Typography>
              {getInquiry.data.resolvedAt && (
                <Typography variant="subtitle1" color={(theme) => theme.palette.success.main}>
                  解決済
                </Typography>
              )}
            </Box>
            <Box display="flex" alignItems="center" gap={1} mb={1}>
              <Typography>お問合せユーザー: </Typography>
              <Avatar src={sender?.iconURL ?? `${baseURL}/user.png`} sx={{ width: 30, height: 30 }} />
              <Typography variant="subtitle1">{sender?.name ?? '退会済メンバー'}</Typography>
            </Box>
            <Typography variant="body1" whiteSpace="pre-wrap">
              {getInquiry.data.content}
            </Typography>
            <Box justifyContent="end">
              <Typography variant="subtitle2">{formatTimestamp(getInquiry.data.createdAt)}</Typography>
            </Box>
          </>
        )}
        {getInquiry.error && <ErrorPage exception={getInquiry.error} />}
      </Stack>
      <Box mt={6}>
        {getInquiry.data && listClientInquiryReplies.data && (
          <Stack spacing={1} mt={1}>
            {listClientInquiryReplies.data.map((v) => (
              <Reply reply={v} members={clientMembers} />
            ))}
            {!getInquiry.data.resolvedAt && <ReplyForm userId={user?.uid ?? ''} onSubmit={createClientInquiryReply} mutate={listClientInquiryReplies.mutate} />}
          </Stack>
        )}
      </Box>
    </>
  )
}

type ReplyProps = {
  reply: ClientInquiryReply
  members: ClientMember[]
}

const Reply = ({ reply, members }: ReplyProps) => {
  const sender = reply.userId ? members.find((m) => m.id === reply.userId) : undefined

  return (
    <Box display="flex" justifyContent={!!reply.userId ? 'end' : 'start'}>
      <Box width="75%">
        {reply.userId &&
          (sender ? (
            <Box display="flex" alignItems="center" gap={1} mb={1}>
              <Avatar src={sender.iconURL ?? `${baseURL}/user.png`} sx={{ width: 30, height: 30 }} />
              <Typography variant="subtitle1">{sender.name}</Typography>
            </Box>
          ) : (
            <Box mb={1}>
              <Avatar src={`${baseURL}/user.png`} sx={{ width: 30, height: 30 }} />
              <Typography variant="subtitle1">退会済メンバー</Typography>
            </Box>
          ))}
        {!reply.userId && (
          <Box mb={1}>
            <Typography variant="subtitle1">運営サポート</Typography>
          </Box>
        )}
        <Paper sx={{ p: 2, backgroundColor: (theme) => (reply.userId ? theme.palette.primary.light : theme.palette.background.default) }}>
          <Box>
            <Typography variant="body1" whiteSpace="pre-wrap">
              {reply.content}
            </Typography>
          </Box>
        </Paper>
        <Box display="flex" justifyContent={!!reply.userId ? 'end' : 'start'} mt={0.5}>
          <Typography variant="subtitle2">{formatTimestamp(reply.createdAt)}</Typography>
        </Box>
      </Box>
    </Box>
  )
}
