import { and, collectionGroup, getDocs, query, where } from '@firebase/firestore'
import { ClientInvitation } from '@maru44/huntre-utils/src/models/clientInvitation'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { firestore } from 'src/utils/firebase'
import useSWR from '../useSWR'

export const useListInvitationsByEmail = (email: string) => {
  const listInvitationsByUser = useSWR(
    `/invitations?email=${email}`,
    async (email: string) => {
      const now = new Date()
      const q = query(
        collectionGroup(firestore, 'clientInvitations'),
        and(where('acceptedAt', '==', null), where('expiresAt', '>=', now), where('email', '==', email), where('deniedAt', '==', null))
      )
      const snap = await getDocs(q)
      return snap.docs.map((v) => getData<ClientInvitation>(v))
    },
    {
      disabled: !email,
    }
  )

  return { listInvitationsByUser }
}
