import { collection, getDocs, or, orderBy, query, where } from '@firebase/firestore'
import { DeviceOrder, DeviceOrderPaymentStatus } from '@maru44/huntre-utils/src/models/deviceOrder'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { firestore } from 'src/utils/firebase.js'
import useSWR from '../useSWR'

export const useOrders = (clientId: string, ...paymentStatuses: DeviceOrderPaymentStatus[]) => {
  const listDeviceOrders = useSWR(
    [`/clients/${clientId}/deviceOrders`, clientId, paymentStatuses],
    async () => {
      const filters = or(...paymentStatuses.map((v) => where('paymentStatus', '==', v)))

      const q = query(collection(firestore, `clients/${clientId}/deviceOrders`), filters, orderBy('createdAt', 'desc'))
      const snap = await getDocs(q)

      return snap.docs.map((v) => getData<DeviceOrder>(v))
    },
    {
      disabled: !clientId,
    }
  )
  // TODO simPlanが更新された時に領収書や請求書を送信する

  return { listDeviceOrders }
}
