import { and, collection, getDocs, limit, orderBy, query, Timestamp, where } from '@firebase/firestore'
import { DeviceMessage } from '@maru44/huntre-utils/src/models/deviceMessage'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { firestore } from 'src/utils/firebase'
import useSWR from '../useSWR'

export const useClientDeviceMessages = (deviceId: string, registeredAt: Timestamp | undefined) => {
  // 登録日以降 & 捕獲 & setupだけ
  const listMessagesForClient = useSWR(
    [`/devices/${deviceId}/deviceMessages?after=`, deviceId],
    async () => {
      const q = query(
        collection(firestore, `devices/${deviceId}/deviceMessages`),
        and(where('createdAt', '>=', registeredAt), where('wakeupCause', 'in', ['ESP_SLEEP_WAKEUP_UNDEFINED', 'ESP_SLEEP_WAKEUP_EXT0'])),
        orderBy('createdAt', 'desc'),
        limit(20)
      )

      const snap = await getDocs(q)
      return snap.docs.map((v) => getData<DeviceMessage>(v))
    },
    {
      disabled: !deviceId || !registeredAt,
    }
  )

  return { listMessagesForClient }
}
