import { collection, deleteDoc, doc, getDoc, getDocs } from '@firebase/firestore'
import { ClientMemberLine } from '@maru44/huntre-utils/src/models/clientMemberLine'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { useCallback } from 'react'
import { NotFoundError } from 'src/utils/error'
import { firestore, useHttpsCallable } from 'src/utils/firebase'
import { capture } from 'src/utils/sentry'
import useSWR from '../useSWR'

export const useClientMemberLine = (clientId: string, clientMemberId: string) => {
  const [call, isCalling, callError] = useHttpsCallable<{ type: string; id: string }, { displayName: string | null; pictureUrl: string | null }>(
    'updateLineProfile'
  )

  const listClientMemberLines = useSWR(
    [`/clients/${clientId}/clientMembers/${clientMemberId}/clientMemberLines`, clientId, clientMemberId],
    async () => {
      const ref = collection(firestore, `clients/${clientId}/clientMembers/${clientMemberId}/clientMemberLines`)
      const snap = await getDocs(ref)
      if (snap.size === 0) {
        return null
      }

      const lines: ClientMemberLine[] = []
      for (const line of snap.docs) {
        const data = getData<ClientMemberLine>(line)
        const res = await call(data)
        if (res) {
          data.displayName = res.data.displayName
          data.pictureUrl = res.data.pictureUrl
        }
        if (callError) {
          capture(callError)
        }
        lines.push(data)
      }
      return lines
    },
    {
      disabled: !clientId || !clientMemberId,
    }
  )

  const deleteClientMemberLine = useCallback(
    async (id: string) => {
      const ref = doc(firestore, `clients/${clientId}/clientMembers/${clientMemberId}/clientMemberLines/${id}`)
      const snap = await getDoc(ref)

      if (!snap.exists) {
        throw new NotFoundError('clientMemberLine', id)
      }
      await deleteDoc(ref)
    },
    [clientId, clientMemberId]
  )

  return {
    listClientMemberLines,
    deleteClientMemberLine,
  }
}
