import _useSWR, { SWRResponse } from 'swr'

// const useSWR = <Data = any, Error = any>(disabled?: boolean, ...args: Parameters<typeof _useSWR<Data, Error>>): SWRResponse<Data, Error> => {
//   const [key, fetcher, ...rest] = args

//   // if key is array
//   if (Array.isArray(key)) {
//     key.push(disabled ?? false)
//   }

//   const _fetcher = disabled ? null : fetcher

//   return _useSWR(key, _fetcher, ...rest)
// }
// export default useSWR

type SWRParameter<Data, Error> = Parameters<typeof _useSWR<Data, Error>>
type Key<Data, Error> = SWRParameter<Data, Error>[0]
type Fetcher<Data, Error> = SWRParameter<Data, Error>[1]
type Options<Data, Error> = SWRParameter<Data, Error>[2] & {
  disabled?: boolean
}

const useSWR = <Data = any, Error = any>(key: Key<Data, Error>, fetcher: Fetcher<Data, Error>, options?: Options<Data, Error>): SWRResponse<Data, Error> => {
  const { disabled, ...opts } = options ?? {}
  // if key is array
  if (Array.isArray(key)) {
    key.push(disabled ?? false)
  }

  const _fetcher = disabled ? null : fetcher

  return _useSWR(key, _fetcher, opts)
}
export default useSWR
