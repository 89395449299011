import { Notification } from '@maru44/huntre-utils/src/models/notification'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { collection, getDocs, orderBy, query } from 'firebase/firestore'
import { firestore } from 'src/utils/firebase'
import useSWR from '../useSWR'

export const useNotifications = (clientId: string, clientMemberId: string) => {
  const listNotifications = useSWR(
    [`/clients/${clientId}/clientMembers/${clientMemberId}/notifications`, clientId, clientMemberId],
    async () => {
      const col = query(collection(firestore, `clients/${clientId}/clientMembers/${clientMemberId}/notifications`), orderBy('createdAt', 'desc'))
      const snap = await getDocs(col)

      return snap.docs.map((v) => getData<Notification>(v))
    },
    {
      disabled: !clientId || !clientMemberId,
    }
  )

  return { listNotifications }
}
