// NOTE: これだけ特殊で useSWRを使わない

import { collection, doc, getDoc, getDocs } from '@firebase/firestore'
import { Client } from '@maru44/huntre-utils/src/models/client'
import { ClientMember } from '@maru44/huntre-utils/src/models/clientMember'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { useCallback } from 'react'
import { NotFoundError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'

// ClientProvider内で使う
export const useClientWithId = () => {
  const getClient = useCallback(async (id: string) => {
    const ref = doc(firestore, `clients/${id}`)
    const snap = await getDoc(ref)
    if (!snap.exists) {
      throw new NotFoundError('client', id)
    }
    const data = getData<Client>(snap)

    return data
  }, [])

  const listClientMembers = useCallback(async (clientId: string) => {
    const col = collection(firestore, `clients/${clientId}/clientMembers`)
    const snap = await getDocs(col)
    return snap.docs.map((v) => getData<ClientMember>(v))
  }, [])

  return { getClient, listClientMembers }
}
