import { ClientMemberRole } from '@maru44/huntre-utils/src/models/clientMember'
import { IsTrappingStatus } from '@maru44/huntre-utils/src/models/device'
import { DeviceMessage, DeviceMessageKind } from '@maru44/huntre-utils/src/models/deviceMessage'
import { Box, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useContext, useMemo, useState } from 'react'
import { IoTrashSharp } from 'react-icons/io5'
import { useParams } from 'react-router-dom'
import { Breads } from 'src/components/atoms/BreadCrumbs'
import { IconPopover } from 'src/components/atoms/IconPopover'
import { StatusChip } from 'src/components/atoms/StatusChip'
import { DeleteClientDeviceDialog } from 'src/components/dialogs/device/DeleteClientDeviceDialog'
import { ClientContext } from 'src/components/providers/ClientProvider'
import { GlobalProductStateContext } from 'src/components/providers/GlobalProductProvider'
import { useClientDevice } from 'src/hooks/clientDevice/useClientDevice'
import { useClientDeviceMessages } from 'src/hooks/clientDevice/useClientDeviceMessages'
import { useClientDeviceMemos } from 'src/hooks/clientDeviceMemo/useClientDeviceMemos'
import { useSim } from 'src/hooks/sim/useSim'
import { ClientDeviceDetail } from 'src/pages/ClientDevice/ClientDeviceDetail'
import { formatTimestamp } from 'src/utils/firebase'
import { ErrorPage } from '../ErrorPage'
import { Memos } from './Memos'
import { DevicePositionContainer } from './Position'

export const ClientDevice = () => {
  const { id } = useParams()
  const { client, clientMembers, currentMember } = useContext(ClientContext)
  const { simPlans } = useContext(GlobalProductStateContext)
  const { getClientDeviceWithDevice } = useClientDevice(id ?? '', client?.id ?? '')
  const { listMessagesForClient } = useClientDeviceMessages(id ?? '', getClientDeviceWithDevice.data?.clientDevice.createdAt)
  const { getSim } = useSim(getClientDeviceWithDevice.data?.device.simId ?? '')
  const { listDeviceMemos } = useClientDeviceMemos(client!.id, id ?? '')

  const { deviceTypes, isLoading: isLoadingDeviceType } = useContext(GlobalProductStateContext)
  const { deviceType } = useMemo(() => {
    return {
      deviceType: deviceTypes?.find((v) => v.id === getClientDeviceWithDevice.data?.device.type),
    }
  }, [deviceTypes, id, getClientDeviceWithDevice.data?.device])

  const [openDelete, setOpenDelete] = useState(false)

  return (
    <>
      <Box>
        <Breads breadIds={['home', 'device']} />
        <Stack spacing={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5">デバイス詳細</Typography>
            {currentMember?.role === ClientMemberRole.Admin && (
              <IconButton onClick={() => setOpenDelete(true)}>
                <IoTrashSharp />
              </IconButton>
            )}
          </Box>
          {!isLoadingDeviceType && getClientDeviceWithDevice.data && !getSim.isLoading && (
            <ClientDeviceDetail
              clientId={client?.id ?? ''}
              data={getClientDeviceWithDevice.data}
              sim={getSim.data ?? null}
              simPlans={simPlans}
              deviceType={deviceType}
              currentMember={currentMember}
              mutate={getClientDeviceWithDevice.mutate}
              mutateSim={getSim.mutate}
            />
          )}
          {getClientDeviceWithDevice.error && <ErrorPage exception={getClientDeviceWithDevice.error} />}
        </Stack>
        {!isLoadingDeviceType && getClientDeviceWithDevice.data && !getSim.isLoading && (
          <Stack spacing={2} mt={8}>
            <Box display="flex" gap={2} alignItems="center">
              <Typography variant="h6">メモ</Typography>
              <IconPopover icon="?">
                <Typography>通知に送る設定をしているメモもしくは最新のメモが通知の際に送付されます。</Typography>
              </IconPopover>
            </Box>
            <Memos
              currentMember={currentMember}
              clientId={client?.id ?? ''}
              deviceId={id ?? ''}
              clientDevice={getClientDeviceWithDevice.data.clientDevice}
              members={clientMembers ?? []}
              memos={listDeviceMemos.data}
              mutateMemos={listDeviceMemos.mutate}
              mutateDevice={getClientDeviceWithDevice.mutate}
            />
          </Stack>
        )}
        {getClientDeviceWithDevice.data && (
          <Stack spacing={2} mt={8}>
            <Typography variant="h6">位置情報</Typography>
            <DevicePositionContainer
              device={getClientDeviceWithDevice.data.device}
              clientDevice={getClientDeviceWithDevice.data.clientDevice}
              clientId={client?.id ?? ''}
            />
          </Stack>
        )}
        {!isLoadingDeviceType && getClientDeviceWithDevice.data && (
          <Stack spacing={2} mt={8}>
            <Typography variant="h6">通信履歴</Typography>
            {listMessagesForClient.data && <MessageList data={listMessagesForClient.data} />}
          </Stack>
        )}
      </Box>
      {!isLoadingDeviceType && getClientDeviceWithDevice.data && (
        <DeleteClientDeviceDialog
          clientId={client!.id}
          clientDevice={getClientDeviceWithDevice.data.clientDevice}
          open={openDelete}
          handleClose={() => setOpenDelete(false)}
        />
      )}
    </>
  )
}

type MessageListProps = {
  data: DeviceMessage[]
}

const MessageList = ({ data }: MessageListProps) => {
  return (
    <TableContainer component={Paper} elevation={6}>
      <Table sx={{ minWidth: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1">種別</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">作動状況</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">通信日時</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((v) => (
            <MessageRow key={v.id} data={v} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

type MessageRowProps = {
  data: DeviceMessage
}

const MessageRow = ({ data }: MessageRowProps) => {
  const status = trappingStatus(data.kind, data.isTrapping)
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {messageKindText(data.kind, data.wakeupCause)}
      </TableCell>
      <TableCell>{status && <StatusChip status={status} />}</TableCell>
      <TableCell>
        <Typography>{formatTimestamp(data.createdAt)}</Typography>
      </TableCell>
    </TableRow>
  )
}

const messageKindText = (kind: DeviceMessageKind, cause: string) => {
  switch (kind) {
    case DeviceMessageKind.SETUP:
      return '電源on'
    case DeviceMessageKind.TRAPPING_STATUS:
      if (cause === 'ESP_SLEEP_WAKEUP_EXT0') {
        return 'トリガー (磁石)'
      }
      return '定期通信'
    case DeviceMessageKind.MEASURE_VOLTAGE:
      return ''
  }
}

const trappingStatus = (kind: DeviceMessageKind, isTrapping: boolean | undefined): IsTrappingStatus | undefined => {
  if (kind === DeviceMessageKind.TRAPPING_STATUS) {
    if (isTrapping) {
      return IsTrappingStatus.Trapping
    }
    return IsTrappingStatus.NotTrapping
  }
}
