import { Box, Stack, Typography } from '@mui/material'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Breads } from 'src/components/atoms/BreadCrumbs'
import { ClientContext } from 'src/components/providers/ClientProvider'
import { helpContents } from './contents'

export const HelpContentPage = () => {
  const { id } = useParams()
  const { client } = useContext(ClientContext)

  const content = helpContents.find((v) => v.id === id)

  if (!content) return null

  return (
    <Box>
      <Breads breadIds={['help']} />
      <Stack spacing={2}>
        <Typography variant="h5">{content.title}</Typography>
        {content.content(client?.id)}
      </Stack>
    </Box>
  )
}
