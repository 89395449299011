import { DeviceOrderPaymentStatus } from '@maru44/huntre-utils/src/models/deviceOrder'
import { DeviceType } from '@maru44/huntre-utils/src/models/deviceType'
import { Box, Button, Card, Stack, Typography } from '@mui/material'
import { useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Breads } from 'src/components/atoms/BreadCrumbs'
import { ImageWindow } from 'src/components/atoms/ImageWindow'
import { LoadingLayout } from 'src/components/layouts/LoadingLayout'
import { ClientContext } from 'src/components/providers/ClientProvider'
import { getDeviceTypeWithPrices, GlobalProductStateContext } from 'src/components/providers/GlobalProductProvider'
import { useOrders } from 'src/hooks/order/useOrders'
import { useStorage } from 'src/hooks/storage/useStorage'
import Stripe from 'stripe'
import { ErrorPage } from '../ErrorPage'
import { DeviceOrdersTable } from './DeviceOrdersTable'
import { PurchaseArea } from './PurchaseForm'

export const DeviceProductPage = () => {
  const { client } = useContext(ClientContext)

  const { listDeviceOrders } = useOrders(client?.id ?? '', DeviceOrderPaymentStatus.Success, DeviceOrderPaymentStatus.Waiting)

  const { isLoading, isLoadingStripe, deviceTypes, devicePrices, simPlans, error } = useContext(GlobalProductStateContext)
  const deviceTypeWithPrices = getDeviceTypeWithPrices(deviceTypes, devicePrices)

  const [selectedDeviceTypeId, setSelectedDeviceTypeId] = useState<string>()

  // NOTE: こんなことはやりたくないがこうしないとdeviceOrders.isLoadingの変化がうまく伝わらない
  const isLoadingOrders = useMemo(() => listDeviceOrders.isLoading, [listDeviceOrders.isLoading])

  if (error || listDeviceOrders.error) {
    return <ErrorPage exception={error || listDeviceOrders.error} />
  }
  if (isLoadingStripe || isLoading) {
    return <LoadingLayout />
  }

  return (
    <Box>
      <Breads breadIds={['deviceOrders']} />
      <Stack spacing={2}>
        <Typography variant="h5">新規注文はこちらから</Typography>
        <Typography color="red">
          現在受注生産とさせていただいております。ご注文は<Link to={`/clients/${client?.id}/inquiries`}>お問い合わせ</Link>からお願いいたします。
          <br />※ stripeの請求書を送付いたします。クレジットカードでお支払いいただけます。
        </Typography>
        <Typography>送料として1,000円いただきます。</Typography>
        {deviceTypeWithPrices?.map((v) => (
          <>
            <Production key={v.deviceType.id} deviceType={v.deviceType} price={v.price} onSelect={setSelectedDeviceTypeId} />
            {selectedDeviceTypeId === v.deviceType.id && (
              <PurchaseArea deviceType={v.deviceType} price={v.price} simPlans={simPlans} onClose={() => setSelectedDeviceTypeId(undefined)} />
            )}
          </>
        ))}
      </Stack>
      <Stack spacing={2} mt={4}>
        <Typography variant="h5">注文履歴</Typography>
        {/*  TODO: fix読み込み終わってるのに反映されない... */}
        {<DeviceOrdersTable orders={!isLoadingOrders ? listDeviceOrders.data : undefined} simPlans={simPlans} />}
      </Stack>
    </Box>
  )
}

type ProductionProps = {
  deviceType: DeviceType
  price: Stripe.Price
  onSelect: (deviceTypeId: string) => void
}

const Production = ({ deviceType, price, onSelect }: ProductionProps) => {
  const [fileURL, setFileURL] = useState<string>('')
  const { getFileURL } = useStorage()

  useEffect(() => {
    if (deviceType.imagePath) {
      getFileURL(deviceType.imagePath, setFileURL)
    }
  }, [deviceType.imagePath, getFileURL, setFileURL])

  return (
    <>
      <Card sx={{ p: 4 }}>
        <Typography variant="h6">{deviceType.displayName}</Typography>
        <Box display="flex" gap={4} alignItems="top" mt={2}>
          <ImageWindow src={fileURL} width={120} height={150} objectFit="cover" />
          <Box>
            {deviceType.description && <Typography sx={{ mb: 2 }}>{deviceType.description}</Typography>}
            <Typography>価格: ¥{price.unit_amount?.toLocaleString('en-US')}</Typography>
            <Typography>在庫: {deviceType.stock}</Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="end">
          <Button variant="contained" onClick={() => onSelect(deviceType.id)}>
            購入に進む
          </Button>
        </Box>
      </Card>
    </>
  )
}
