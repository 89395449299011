import { doc, getDoc } from '@firebase/firestore'
import { Device } from '@maru44/huntre-utils/src/models/device'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { NotFoundError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'
import useSWR from '../useSWR'

export const useDevice = (id: string) => {
  const getDevice = useSWR(
    [`/devices/${id}`, id],
    async () => {
      const deviceRef = doc(firestore, `devices/${id}`)
      const deviceSnap = await getDoc(deviceRef)

      if (!deviceSnap.exists()) {
        throw new NotFoundError('device', id)
      }
      const data = getData<Device>(deviceSnap)
      if (!data) {
        throw new NotFoundError('device', id)
      }

      return data
    },
    {
      disabled: !id,
    }
  )

  return { getDevice }
}
