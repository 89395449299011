import { addDoc, collection, getDocs, orderBy, query, serverTimestamp } from '@firebase/firestore'
import { ClientInquiry, ClientInquiryInput } from '@maru44/huntre-utils/src/models/clientInquiry'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { useCallback } from 'react'
import { firestore } from 'src/utils/firebase'
import useSWR from '../useSWR'

export const useClientInquiries = (clientId: string) => {
  const listClientInquiries = useSWR(
    [`/clients/${clientId}/clientInquiries`, clientId],
    async () => {
      const q = query(collection(firestore, `clients/${clientId}/clientInquiries`), orderBy('createdAt', 'desc'))
      const refs = await getDocs(q)

      return refs.docs.map((v) => getData<ClientInquiry>(v))
    },
    {
      disabled: !clientId,
    }
  )

  const createInquiry = useCallback(
    async (input: ClientInquiryInput) => {
      const ref = collection(firestore, `clients/${clientId}/clientInquiries`)
      const now = serverTimestamp()
      await addDoc(ref, {
        ...input,
        resolvedAt: null,
        createdAt: now,
        updatedAt: now,
      })
    },
    [clientId]
  )

  return { listClientInquiries, createInquiry }
}
