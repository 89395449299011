import { User } from '@maru44/huntre-utils/src/models/user'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { doc, getDoc } from 'firebase/firestore'
import { NotFoundError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'
import useSWR from '../useSWR'

export const useUser = (id: string) => {
  const getUser = useSWR(
    [`/users/${id}`, id],
    async () => {
      const ref = doc(firestore, `users/${id}`)
      const snap = await getDoc(ref)
      if (!snap.exists) {
        throw new NotFoundError('user', id)
      }
      const data = getData<User>(snap)

      if (!data) {
        throw new NotFoundError('user', id)
      }

      return { data, ref }
    },
    {
      disabled: !id,
    }
  )

  return { getUser }
}
