import { Notification, NotificationConverter } from '@maru44/huntre-utils/src/models/notification'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { NotFoundError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'
import useSWR from '../useSWR'

export const useNotification = (clientId: string, clientMemberId: string, id: string) => {
  const getNotification = useSWR(
    [`/clients/${clientId}/clientMembers/${clientMemberId}/notifications/${id}`, clientId, clientMemberId, id],
    async () => {
      const ref = doc(firestore, `clients/${clientId}/clientMembers/${clientMemberId}/notifications/${id}`).withConverter(NotificationConverter)
      const snap = await getDoc(ref)
      if (!snap.exists()) {
        throw new NotFoundError('notification', id)
      }

      const now = serverTimestamp()
      await updateDoc(ref, {
        readAt: now,
        updatedAt: now,
      })

      return getData<Notification>(snap)
    },
    {
      disabled: !clientId || !clientMemberId || !id,
    }
  )

  return { getNotification }
}
