import { Sim } from '@maru44/huntre-utils/src/models/sim'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { doc, getDoc } from 'firebase/firestore'
import { NotFoundError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'
import useSWR from '../useSWR'

export const useSim = (id: string) => {
  const getSim = useSWR(
    '/sim',
    async () => {
      const ref = doc(firestore, `sims/${id}`)
      const snap = await getDoc(ref)
      if (!snap.exists) {
        throw new NotFoundError('sim', id)
      }

      return getData<Sim>(snap)
    },
    {
      disabled: !id,
    }
  )

  return { getSim }
}
