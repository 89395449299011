import { Link, Stack, Typography } from '@mui/material'
import { ReactNode } from 'react'

export type HelpContent = {
  id: string
  title: string
  summary: string
  content: (clientId?: string) => ReactNode
}

export const helpContents: HelpContent[] = [
  {
    id: 'line',
    title: 'LINE通知の設定方法',
    summary: 'LINE通知の設定方法はこちら',
    content: (clientId?: string) => (
      <Stack spacing={2}>
        <Typography>以下の画像に従ってLINE通知を設定します。</Typography>
        <Typography>
          {clientId ? (
            <>
              画像に従って右上のアイコンをクリックしてユーザー名をクリックするか、<Link href={`/clients/${clientId}/profile`}>こちら</Link>
              のリンクにアクセスします。
            </>
          ) : (
            'ログインして画像に従って右上のアイコンをクリックしてユーザー名をクリックしてください。'
          )}
          <img style={{ border: '1px solid #3f3f3f' }} src="/helpimages/edit_profile1.png" width="100%" />
          <img style={{ border: '1px solid #3f3f3f' }} src="/helpimages/edit_profile2.png" width="100%" />
        </Typography>
        <Stack spacing={1}>
          <Typography>
            ワナップ公式LINEをQRコード等から友だち登録してください。
            <br />
            グループLINEでご利用になりたい場合はグループにワナップ公式LINEを追加してください。
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Typography>
            LINE通知をONにして、ページ下部に表示されたコードをコピーしてワナップ公式ラインに貼り付けて送信してください。
            <br />
            グループLINEでご利用になりたい場合はグループLINEにコードを貼り付けて送信してください。
          </Typography>
          <img style={{ border: '1px solid #3f3f3f' }} src="/helpimages/edit_profile3.png" width="100%" />
        </Stack>
      </Stack>
    ),
  },
]
