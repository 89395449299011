import { Client, clientConverter, ClientWithMembers } from '@maru44/huntre-utils/src/models/client'
import { ClientMember } from '@maru44/huntre-utils/src/models/clientMember'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { collection, doc, getDoc, getDocs, serverTimestamp, updateDoc } from 'firebase/firestore'
import { useCallback } from 'react'
import { NotFoundError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'
import useSWR from '../useSWR'

type UpdateClientInput = {
  name: string
  iconURL: string
  address: string
}

export const useClient = (id: string) => {
  // const getClient = useCallback(async () => {
  //   const ref = doc(firestore, `clients/${id}`).withConverter(clientConverter)
  //   const snap = await getDoc(ref)
  //   if (!snap.exists) {
  //     throw new NotFoundError('client', id)
  //   }
  //   const data = getData<Client>(snap)

  //   if (!data) {
  //     throw new NotFoundError('client', id)
  //   }

  //   return data
  // }, [id])

  const getClientWithMembers = useSWR([`/clients/${id}`, id], async (): Promise<ClientWithMembers | undefined> => {
    const ref = doc(firestore, `clients/${id}`)
    const snap = await getDoc(ref)
    if (!snap.exists) {
      throw new NotFoundError('client', id)
    }
    const client = getData<Client>(snap)

    const col = collection(firestore, `clients/${client?.id}/clientMembers`)
    const membersSnap = await getDocs(col)

    const members = membersSnap.docs.map((v) => getData<ClientMember>(v))

    const ownerRef = doc(firestore, `clients/${client.id}/clientMembers`, client.ownerMemberId)
    const ownerSnap = await getDoc(ownerRef)
    if (!ownerSnap.exists) {
      throw new NotFoundError('clientMember', id)
    }
    const owner = getData<ClientMember>(ownerSnap)

    return {
      ...client!,
      clientMembers: members!,
      ownerMember: owner!,
      createdMember: owner!,
    }
  })

  const updateClientMembers = useCallback(
    async (memberIds: string[]) => {
      if (memberIds.length === 0) {
        return
      }
      const ref = doc(firestore, `clients/${id}`)
      const snap = await getDoc(ref.withConverter(clientConverter))
      if (!snap.exists) {
        throw new NotFoundError('client', id)
      }
      const data = snap.data()

      if (!data) {
        throw new NotFoundError('client', id)
      }

      await updateDoc(ref, {
        clientMemberIds: memberIds,
        updatedAt: serverTimestamp(),
      })
    },
    [id]
  )

  const updateClient = useCallback(
    async (input: UpdateClientInput) => {
      const ref = doc(firestore, `clients/${id}`).withConverter(clientConverter)
      const snap = await getDoc(ref)
      if (!snap.exists) {
        throw new NotFoundError('client', id)
      }

      await updateDoc(ref, {
        ...input,
        updatedAt: new Date(),
      })
    },
    [id]
  )

  return { getClientWithMembers, updateClientMembers, updateClient }
}
