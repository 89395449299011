import { ClientMemberNotificationMethod } from '@maru44/huntre-utils/src/models/clientMember'
import { ClientMemberLine } from '@maru44/huntre-utils/src/models/clientMemberLine'
import { Avatar, Box, Button, IconButton, Paper, Stack, TextField, Typography } from '@mui/material'
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { IoCopySharp } from 'react-icons/io5'
import { BaseDialog } from 'src/components/atoms/BaseDialog'
import { Breads } from 'src/components/atoms/BreadCrumbs'
import { SimpleGrid, SimpleGridRow } from 'src/components/atoms/SimpleGrid'
import { AuthContext } from 'src/components/providers/AuthProvider'
import { ClientContext } from 'src/components/providers/ClientProvider'
import { baseURL, lineId } from 'src/config'
import { useClientMemberLine } from 'src/hooks/client/useClientMemberLine'
import { useStorage } from 'src/hooks/storage/useStorage'
import { capture } from 'src/utils/sentry'
import { UpdateForm } from './UpdateForm'

const displayNotificationMethod = (method: ClientMemberNotificationMethod, clientMemberLine: ClientMemberLine | null | undefined) => {
  switch (method) {
    case 'email':
      return 'メール'
    case 'line':
      if (!clientMemberLine) {
        return 'LINE (下記の方法に従ってLINE連携をおこなって下さい)'
      }
      return 'LINE'
  }
}

export const ProfileSettingPage: FC = () => {
  const { client, clientMembers, selectClient } = useContext(ClientContext)
  const [user] = useContext(AuthContext)

  const [updateMode, setUpdateMode] = useState(false)
  const [lineQR, setLineQR] = useState<string | null>(null)
  const [clientMemberLineId, setClientMemberLine] = useState<string | undefined>()

  const { getFileURL } = useStorage()

  const currentMember = useMemo(() => {
    return clientMembers.find((m) => m.id === user?.uid)
  }, [user?.uid, clientMembers])

  const { listClientMemberLines, deleteClientMemberLine } = useClientMemberLine(client?.id ?? '', currentMember?.id ?? '')

  const handleDeleteClientMemberLine = useCallback(async () => {
    if (!clientMemberLineId) {
      return
    }
    try {
      await deleteClientMemberLine(clientMemberLineId)
      toast.success('LINE連携の解除に成功しました')
      setClientMemberLine(undefined)
      await listClientMemberLines.mutate()
    } catch (e) {
      capture(e)
      toast.error('LINE連携の解除に失敗しました')
    }
  }, [clientMemberLineId, setClientMemberLine, deleteClientMemberLine, listClientMemberLines.mutate])

  const currentMemberData = useMemo((): SimpleGridRow[] => {
    return [
      {
        head: 'アイコン',
        body: <Avatar src={currentMember?.iconURL ?? `${baseURL}/user.png`} sx={{ width: 30, height: 30 }} />,
      },
      {
        head: '表示名',
        body: currentMember?.name,
      },
      {
        head: '電話番号',
        body: currentMember?.phone,
      },
      {
        head: '通知方法',
        body: (
          <Typography whiteSpace="pre-wrap">
            {currentMember?.notificationMethods.map((v) => displayNotificationMethod(v, listClientMemberLines?.data?.[0])).join('\n')}
          </Typography>
        ),
      },
      currentMember?.notificationMethods.includes('line') && listClientMemberLines.data && listClientMemberLines.data.length > 0
        ? {
            head: '連携中のLINE',
            body: (
              <Box display="flex" gap={1} alignItems="end">
                <LineProfile line={listClientMemberLines.data[0]} />
                <Button variant="outlined" onClick={() => setClientMemberLine(listClientMemberLines!.data![0].id)}>
                  連携を解除する
                </Button>
              </Box>
            ),
          }
        : {},
      // (currentMember?.notificationMethods.includes('line') && client.)
    ]
  }, [currentMember, listClientMemberLines.data])

  const handleCopyToClipBoard = useCallback(async () => {
    await navigator.clipboard.writeText(`code: ${client?.id}/clientMembers/${currentMember?.id}`)
    toast.success('コードをコピーしました。そのままLINEにご入力ください。')
  }, [client?.id, currentMember?.id])

  useEffect(() => {
    getFileURL('static/line/M_gainfriends_2dbarcodes_GW.png', setLineQR)
  }, [setLineQR, getFileURL])

  return (
    <>
      <Breads breadIds={['profile']} />
      <Stack spacing={2}>
        <Typography variant="h5">プロフィール設定</Typography>
        {client && currentMember && updateMode && (
          <Stack mt={4} spacing={2}>
            <Typography>
              プロフィール設定は現在選択中のグループ内でのみ反映されます。他のグループには影響を及ぼしません。
              <br />
              また、グループ内の個人のみの設定となり他人への影響はありません。
            </Typography>
            <UpdateForm client={client} clientMember={currentMember} onSubmit={() => setUpdateMode(false)} selectClient={selectClient} />
            {!listClientMemberLines.isLoading && listClientMemberLines.data && listClientMemberLines.data.length > 0 && (
              <>
                <Typography>連携中のLINE</Typography>
                <Box mt={2}>
                  <LineProfile line={listClientMemberLines.data[0]} />
                </Box>
              </>
            )}
          </Stack>
        )}
        {client && currentMember && !updateMode && (
          <Stack mt={4} spacing={2}>
            <SimpleGrid mt={4} rows={currentMemberData} />
            <Box display="flex">
              <Button variant="contained" onClick={() => setUpdateMode(true)}>
                プロフィールを変更する
              </Button>
            </Box>
          </Stack>
        )}
      </Stack>
      <Box mt={6}>
        {!listClientMemberLines.isLoading && !listClientMemberLines.data && (
          <>
            <Typography>
              LINE通知を利用するには通知方法にLINEを追加し、さらに以下の処理を行う必要があります。
              <br />
              以下のQRコードもしくは
              <a href={`https://line.me/R/ti/p/@${lineId}`} target="_blank">
                こちらのリンク
              </a>
              から友達追加を行い、さらに下記のコードをチャット欄に入力する必要があります。
              <br />
              グループに公式アカウントを追加することもできます。その場合はグループ内の他の人と設定が重複しないように注意して下さい。
              重複すると同じメッセージが複数個飛んでしまいます。
            </Typography>
            <img src={lineQR ?? undefined} width={300} />
            <Box display="flex">
              <TextField sx={{ width: '80%' }} disabled value={`code: ${client?.id}/clientMembers/${currentMember?.id}`} />
              <IconButton onClick={handleCopyToClipBoard}>
                <IoCopySharp />
              </IconButton>
            </Box>
          </>
        )}
      </Box>
      <BaseDialog
        open={!!clientMemberLineId}
        title="LINE連携の解除"
        content="LINE連携を解除しますか？"
        actionText="解除する"
        onClose={() => setClientMemberLine(undefined)}
        onAction={handleDeleteClientMemberLine}
      />
    </>
  )
}

type LineProfileProps = {
  line: ClientMemberLine
}

export const LineProfile = (props: LineProfileProps) => {
  return (
    <Paper sx={{ width: '240px', padding: 1 }}>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Avatar src={props.line.pictureUrl ?? `${baseURL}/user.png`} sx={{ width: 30, height: 30 }} />
        <Typography mt={1} width="100%" sx={{ wordBreak: 'break-word' }} textAlign="center">
          {props.line.displayName} {props.line.type === 'group' && ' (グループ)'}
        </Typography>
      </Box>
    </Paper>
  )
}
