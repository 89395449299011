import { zodResolver } from '@hookform/resolvers/zod'
import { ClientApplicationStatus, ClientType, ClientWithMembers } from '@maru44/huntre-utils/src/models/client'
import { ClientInvitation } from '@maru44/huntre-utils/src/models/clientInvitation'
import { ClientMember, ClientMemberRole, ClientMemberStatus } from '@maru44/huntre-utils/src/models/clientMember'
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Popover,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { FormEvent, MouseEvent, useCallback, useContext, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { IoEllipsisVerticalSharp } from 'react-icons/io5'
import { useParams } from 'react-router-dom'
import { BaseDialog } from 'src/components/atoms/BaseDialog'
import { Breads } from 'src/components/atoms/BreadCrumbs'
import { SimpleGrid, SimpleGridRow } from 'src/components/atoms/SimpleGrid'
import { AuthContext } from 'src/components/providers/AuthProvider'
import { ClientContext } from 'src/components/providers/ClientProvider'
import { baseURL } from 'src/config'
import { useClient } from 'src/hooks/client/useClient'
import { useClientInvitation } from 'src/hooks/client/useClientInvitation'
import { useClientInvitations } from 'src/hooks/client/useClientInvitations'
import { useClientMember } from 'src/hooks/client/useClientMember'
import { useUser } from 'src/hooks/user/useUser'
import { NotAccessibleClientError } from 'src/utils/error'
import { capture } from 'src/utils/sentry'
import { KeyedMutator } from 'swr'
import { z } from 'zod'
import { ErrorPage } from '../ErrorPage'

export const ClientPage = () => {
  const { id } = useParams()
  const { currentMember } = useContext(ClientContext)
  const { getClientWithMembers } = useClient(id ?? '')

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [selectedMember, setSelectedMember] = useState<ClientMember | undefined>()
  const [action, setAction] = useState<'update:member' | 'delete:member' | 'delete:invitation' | undefined>()
  const { deleteClientMember, updateClientMemberByAdmin } = useClientMember(id ?? '', selectedMember?.id ?? '')

  const [selectedInvitation, setSelectedInvitation] = useState<ClientInvitation | undefined>()
  const { deleteInvitation } = useClientInvitation(id ?? '', selectedInvitation?.id ?? '')
  const { listClientInvitations } = useClientInvitations(id ?? '')

  const [memberRole, setMemberRole] = useState<ClientMemberRole | undefined>()

  const data = getClientWithMembers.data

  const handleCloseDialog = useCallback(() => {
    setSelectedMember(undefined)
    setAnchorEl(null)
    setAction(undefined)
  }, [setSelectedMember, setAnchorEl, setAction])

  const handleDeleteMember = useCallback(async () => {
    try {
      if (!selectedMember) {
        return
      }
      await deleteClientMember(selectedMember.id)
      await getClientWithMembers.mutate()
    } catch (e) {
      toast.error('メンバーが削除できませんでした')
      capture(e)
      return
    }

    toast.success('メンバーを削除しました')
    handleCloseDialog()
  }, [handleCloseDialog, deleteClientMember, getClientWithMembers.mutate, selectedMember])

  const handleUpdateMember = useCallback(async () => {
    try {
      if (!selectedMember || !memberRole) {
        return
      }
      await updateClientMemberByAdmin({
        role: memberRole,
        notificationMethods: selectedMember.notificationMethods, // とりあえずまだそのまま
      })
    } catch (e) {
      toast.error('メンバーが編集できませんでした')
      capture(e)
      return
    }
    toast.success('メンバーを編集しました')
    getClientWithMembers.mutate()
    handleCloseDialog()
  }, [memberRole, selectedMember, updateClientMemberByAdmin, handleCloseDialog, getClientWithMembers.mutate])

  const handleDeleteInvitation = useCallback(async () => {
    try {
      if (!selectedInvitation) {
        return
      }
      await deleteInvitation()
    } catch (e) {
      toast.error('招待を削除できませんでした')
      capture(e)
      return
    }

    toast.success('招待を削除しました')
    await listClientInvitations.mutate()
    handleCloseDialog()
  }, [handleCloseDialog, deleteInvitation, listClientInvitations.mutate, selectedInvitation])

  const rows = useMemo((): SimpleGridRow[] => {
    if (!data) {
      return []
    }
    if (!['approved', 'applied', 'reapplied'].includes(data.applicationStatus) || data.status === 'inactive') {
      return []
    }

    if (data.type === ClientType.Group) {
      return [
        {
          head: '名前',
          body: data.name,
        },
        {
          head: 'タイプ',
          body: 'グループ',
        },
        //   {
        //     head: 'プラン',
        //   },
        {
          head: '住所',
          body: data.address,
        },
        {
          head: <Typography variant="h6">メンバー</Typography>,
        },
        {
          head: '一覧',
          body: (
            <Box>
              {data.clientMembers
                .filter((v) => v.status === ClientMemberStatus.Joined)
                .map((v) => (
                  <Member
                    member={v}
                    canEdit={v.id !== data.ownerMember.id && currentMember?.role === ClientMemberRole.Admin}
                    isOwner={v.id === data.ownerMember.id}
                    key={v.id}
                    onClick={(e) => {
                      setSelectedMember(v)
                      setAnchorEl(e.currentTarget)
                    }}
                  />
                ))}
            </Box>
          ),
          alignItems: 'top',
        },
        {
          head: '招待',
          body: (
            <Box>
              {listClientInvitations.data?.map((v) => (
                <InvitationRow
                  onClick={(e) => {
                    setSelectedInvitation(v)
                    setAnchorEl(e.currentTarget)
                  }}
                  canEdit={currentMember?.role === ClientMemberRole.Admin}
                  member={v}
                  key={v.id}
                />
              ))}
            </Box>
          ),
          alignItems: 'top',
        },
      ]
    }

    return [
      {
        head: '名前',
        body: data.name,
      },
      {
        head: 'タイプ',
        body: 'ユーザー',
      },
      //   {
      //     head: 'プラン',
      //   },
      {
        head: '住所',
        body: data.address,
      },
    ]
  }, [data, currentMember, listClientInvitations.data, setSelectedMember, setSelectedInvitation, setAnchorEl])

  // TODO clientがapprovedじゃないときのを作る
  return (
    <Box>
      <Breads breadIds={['clients', 'client']} />
      <Stack spacing={2}>
        <Typography variant="h5">グループ詳細</Typography>
        {data && data.applicationStatus === ClientApplicationStatus.Approved && currentMember && <SimpleGrid rows={rows} />}
        {getClientWithMembers.error && <ErrorPage exception={getClientWithMembers.error} />}
        {/* 所属していない */}
        {data && data.applicationStatus === ClientApplicationStatus.Approved && !currentMember && (
          <ErrorPage exception={new NotAccessibleClientError('not member', id ?? '')} />
        )}
      </Stack>
      {/* 招待 */}
      {data &&
        data.applicationStatus === ClientApplicationStatus.Approved &&
        data.type === ClientType.Group &&
        currentMember?.status === ClientMemberStatus.Joined &&
        currentMember?.role === ClientMemberRole.Admin && <InvitationForm data={data} id={id ?? ''} mutate={listClientInvitations.mutate} />}
      {data && data.applicationStatus === ClientApplicationStatus.Approved && currentMember && (
        <>
          <Popover
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            open={!!anchorEl && (!!selectedMember || !!selectedInvitation)}
            onClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
          >
            <Stack>
              {selectedMember && (
                <>
                  <Divider />
                  <Typography component={Button} onClick={() => setAction('update:member')}>
                    編集する
                  </Typography>
                  <Typography component={Button} onClick={() => setAction('delete:member')}>
                    削除する
                  </Typography>
                </>
              )}
              {selectedInvitation && (
                <Typography component={Button} onClick={() => setAction('delete:invitation')}>
                  削除する
                </Typography>
              )}
            </Stack>
          </Popover>
          {/* member削除用 */}
          <BaseDialog
            open={!!selectedMember && action === 'delete:member'}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="sm"
            title={`${selectedMember?.name}の削除`}
            content={`${selectedMember?.name}をグループから削除しますか？`}
            actionText="削除"
            onAction={handleDeleteMember}
          />
          {/* member編集用 */}
          <Dialog
            open={!!selectedMember && action === 'update:member'}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="sm"
            title={`${selectedMember?.name}の編集`}
          >
            <DialogTitle>{selectedMember?.name}の編集</DialogTitle>
            <DialogContent>
              <InputLabel>権限</InputLabel>
              <Select
                fullWidth
                required
                onChange={(e) => {
                  setMemberRole(e.target.value as ClientMemberRole)
                }}
                defaultValue={selectedMember?.role ?? 'member'}
              >
                <MenuItem value="member">通常メンバー</MenuItem>
                <MenuItem value="admin">管理メンバー</MenuItem>
              </Select>
            </DialogContent>
            <DialogActions>
              <Box display="flex" width="100%" p={1} justifyContent="space-between">
                <Button variant="outlined" onClick={handleCloseDialog}>
                  キャンセル
                </Button>
                <Button variant="contained" onClick={handleUpdateMember}>
                  編集
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
          {/* invitation削除用 */}
          <BaseDialog
            open={!!selectedInvitation && action === 'delete:invitation'}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="sm"
            title={`${selectedInvitation?.email}への招待の削除`}
            content={`${selectedInvitation?.email}の招待を削除しますか？`}
            actionText="削除"
            onAction={handleDeleteInvitation}
          />
        </>
      )}
    </Box>
  )
}

// TODO メンバー操作 (adminなら)
// 通知方法は自分で
// 追加 削除
// role等の編集

type ClientMemberLike = {
  email: string
  role: ClientMemberRole
  name?: string
  iconURL?: string
  status?: ClientMemberStatus
}

type MemberProps = {
  member: ClientMemberLike
  canEdit: boolean
  isOwner?: boolean
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
}

const Member = ({ member, canEdit, isOwner, onClick }: MemberProps) => {
  return (
    <Box padding={0.5} display="flex" component={Paper} justifyContent="space-between" alignItems="center">
      <Box display="flex" gap={2}>
        <Box display="flex" alignItems="center" gap={1} width={(theme) => theme.spacing(25)} overflow="hidden">
          <Avatar src={member.iconURL ?? `${baseURL}/user.png`} sx={{ width: 30, height: 30 }} />
          <Typography noWrap variant="subtitle1">
            {member.name ?? member.email}
          </Typography>
        </Box>
        <Typography>{isOwner ? 'オーナー' : member.role === ClientMemberRole.Admin ? '管理' : 'メンバー'}</Typography>
      </Box>
      {member.status === ClientMemberStatus.Retired && <Typography color={(theme) => theme.palette.error.light}>脱退</Typography>}
      {member.status === ClientMemberStatus.Joined && canEdit && onClick && (
        <IconButton onClick={onClick}>
          <IoEllipsisVerticalSharp />
        </IconButton>
      )}
    </Box>
  )
}

const InvitationRow = ({ member, canEdit, onClick }: MemberProps) => {
  return (
    <Box padding={0.5} display="flex" component={Paper} justifyContent="space-between" alignItems="center">
      <Box display="flex" alignItems="center" gap={1}>
        <Avatar src={member.iconURL ?? `${baseURL}/user.png`} sx={{ width: 30, height: 30 }} />
        <Typography variant="subtitle1">{member.name ?? member.email}</Typography>
      </Box>
      {canEdit && onClick && (
        <IconButton onClick={onClick}>
          <IoEllipsisVerticalSharp />
        </IconButton>
      )}
    </Box>
  )
}

const schema = z.object({
  email: z.string().email(),
  role: z.string(),
})
export type FormState = z.infer<typeof schema>
const defaultValues: FormState = {
  email: '',
  role: 'member',
}

type InvitationFormProps = {
  id: string
  data: ClientWithMembers
  mutate: KeyedMutator<ClientInvitation[]>
}

const InvitationForm = ({ id, data, mutate }: InvitationFormProps) => {
  const { control, reset, handleSubmit, formState } = useForm<FormState>({
    resolver: zodResolver(schema),
    defaultValues,
    mode: 'all',
  })

  const [user] = useContext(AuthContext)

  const { getUser } = useUser(user?.uid ?? '')
  const { createInvitation } = useClientInvitations(id)

  const submit = useCallback(
    async (e: FormEvent) => {
      try {
        e.preventDefault()
        if (!getUser.data?.data || !data) {
          return
        }
        await handleSubmit((v) => createInvitation(v.email, v.role as ClientMemberRole, data, getUser.data?.data!))()
      } catch (e) {
        capture(e)
        toast.error('招待に失敗しました')
        return
      }
      reset()
      toast.success('招待に成功しました')
      await mutate()
    },
    [handleSubmit, createInvitation, reset, getUser.data?.data, data, mutate]
  )

  return (
    <Stack spacing={2} mt={6} component="form" onSubmit={submit}>
      <Typography variant="h6">メンバー招待</Typography>
      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <>
            <InputLabel>メールアドレス</InputLabel>
            <TextField required fullWidth type="email" {...field} error={!!formState.errors?.email} helperText={formState.errors?.email?.message} />
          </>
        )}
      />
      <Controller
        name="role"
        control={control}
        defaultValue={ClientMemberRole.Member}
        render={({ field }) => (
          <>
            <InputLabel>役割</InputLabel>
            <Select
              fullWidth
              {...field}
              onChange={(e) => {
                field.onChange(e.target.value as string)
              }}
            >
              <MenuItem value={ClientMemberRole.Member}>通常メンバー</MenuItem>
              <MenuItem value={ClientMemberRole.Admin}>管理メンバー</MenuItem>
            </Select>
          </>
        )}
      />
      <Button variant="contained" type="submit">
        招待する
      </Button>
    </Stack>
  )
}
