import { deleteDoc, doc, getDoc } from '@firebase/firestore'
import { useCallback } from 'react'
import { NotFoundError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'

export const useClientInvitation = (clientId: string, id: string) => {
  const deleteInvitation = useCallback(async () => {
    const ref = doc(firestore, `clients/${clientId}/clientInvitations/${id}`)
    const snap = await getDoc(ref)

    if (!snap.exists()) {
      throw new NotFoundError('clientInvitation', id)
    }

    await deleteDoc(ref)
  }, [clientId, id])

  return { deleteInvitation }
}
