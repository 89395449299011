import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, serverTimestamp, updateDoc } from '@firebase/firestore'
import { ClientInquiry, clientInquiryConverter, ClientInquiryReply, ClientInquiryReplyInput } from '@maru44/huntre-utils/src/models/clientInquiry'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { useCallback } from 'react'
import { NotFoundError, ValidationError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'
import useSWR from '../useSWR'

export const useClientInquiry = (clientId: string, inquiryId: string) => {
  const getInquiry = useSWR(
    [`/clients/${clientId}/clientInquiries/${inquiryId}`, clientId, inquiryId],
    async () => {
      const ref = doc(firestore, `clients/${clientId}/clientInquiries/${inquiryId}`)
      const snap = await getDoc(ref)

      if (!snap.exists() || !snap.data()) {
        throw new NotFoundError('clientInquiry', inquiryId)
      }
      return getData<ClientInquiry>(snap)
    },
    {
      disabled: !clientId || !inquiryId,
    }
  )

  const updateInquiryResolved = useCallback(
    async (resolve: boolean) => {
      const ref = doc(firestore, `clients/${clientId}/clientInquiries/${inquiryId}`).withConverter(clientInquiryConverter)
      const snap = await getDoc(ref)

      if (!snap.exists() || !snap.data()) {
        throw new NotFoundError('clientInquiry', inquiryId)
      }
      if (!!snap.data().resolvedAt) {
        throw new ValidationError(`already resolved: ${inquiryId}`)
      }

      await updateDoc(ref, {
        resolvedAt: resolve ? serverTimestamp() : null,
      })
    },
    [clientId, inquiryId]
  )

  const listClientInquiryReplies = useSWR(
    [`/clients/${clientId}/clientInquiries/${inquiryId}/clientInquiryReplies`, clientId, inquiryId],
    async () => {
      const q = query(collection(firestore, `clients/${clientId}/clientInquiries/${inquiryId}/clientInquiryReplies`), orderBy('createdAt', 'asc'))
      const refs = await getDocs(q)

      return refs.docs.map((v) => getData<ClientInquiryReply>(v))
    },
    {
      disabled: !clientId || !inquiryId,
    }
  )

  const createClientInquiryReply = useCallback(
    async (input: ClientInquiryReplyInput) => {
      const ref = collection(firestore, `clients/${clientId}/clientInquiries/${inquiryId}/clientInquiryReplies`)

      const now = serverTimestamp()
      await addDoc(ref, {
        ...input,
        createdAt: now,
        updatedAt: now,
      })
    },
    [clientId, inquiryId]
  )

  const deleteClientInquiryReply = useCallback(
    async (id: string) => {
      const ref = doc(firestore, `clients/${clientId}/clientInquiries/${inquiryId}/clientInquiryReplies/${id}`)
      await deleteDoc(ref)
    },
    [clientId, inquiryId]
  )

  return { getInquiry, updateInquiryResolved, listClientInquiryReplies, createClientInquiryReply, deleteClientInquiryReply }
}
