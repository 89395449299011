import { addDoc, collection, deleteDoc, doc, getDocs, orderBy, query, serverTimestamp } from '@firebase/firestore'
import { ClientDeviceMemo } from '@maru44/huntre-utils/src/models/clientDeviceMemo'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { useCallback } from 'react'
import { firestore } from 'src/utils/firebase'
import useSWR from '../useSWR'

export const useClientDeviceMemos = (clientId: string, deviceId: string) => {
  const listDeviceMemos = useSWR(
    [`/clients/${clientId}/clientDevices/${deviceId}/clientDeviceMemos`, clientId, deviceId],
    async () => {
      const col = query(collection(firestore, `clients/${clientId}/clientDevices/${deviceId}/clientDeviceMemos`), orderBy('createdAt', 'desc'))
      const snaps = await getDocs(col)

      return snaps.docs.map((v) => getData<ClientDeviceMemo>(v))
    },
    {
      disabled: !clientId || !deviceId,
    }
  )

  const createDeviceMemo = useCallback(
    async (author: { id: string; name: string }, content: string) => {
      const col = collection(firestore, `clients/${clientId}/clientDevices/${deviceId}/clientDeviceMemos`)

      const now = serverTimestamp()
      await addDoc(col, {
        content,
        authorId: author.id,
        authorName: author.name,
        createdAt: now,
        updatedAt: now,
      })
    },
    [clientId, deviceId]
  )

  const deleteDeviceMemo = useCallback(
    async (id: string) => {
      const ref = doc(firestore, `clients/${clientId}/clientDevices/${deviceId}/clientDeviceMemos/${id}`)

      await deleteDoc(ref)
    },
    [clientId, deviceId]
  )

  return { listDeviceMemos, createDeviceMemo, deleteDeviceMemo }
}
