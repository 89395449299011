import { Box, Grid, Paper, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { Breads } from 'src/components/atoms/BreadCrumbs'
import { HelpContent, helpContents } from './contents/contents'

export const HelpPage = () => {
  return (
    <Box>
      <Breads breadIds={['help']} />
      <Stack spacing={2}>
        <Typography variant="h5">ヘルプ</Typography>
        <Grid container spacing={2}>
          {helpContents.map((v) => (
            <HelpContentItem content={v} />
          ))}
        </Grid>
      </Stack>
    </Box>
  )
}

const HelpContentItem = ({ content }: { content: HelpContent }) => {
  return (
    <Grid item xs={6}>
      <Link to={`/help/${content.id}`} style={{ textDecoration: 'none' }}>
        <Paper elevation={6} sx={{ padding: 2 }}>
          <Typography>
            <b>{content.title}</b>
          </Typography>
          <Typography mt={2}>{content.summary}</Typography>
        </Paper>
      </Link>
    </Grid>
  )
}
