import { DeviceType } from '@maru44/huntre-utils/src/models/deviceType'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import { useCallback } from 'react'
import { isDevelopment } from 'src/config'
import { NotFoundError, ValidationError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'

export const useDeviceType = () => {
  const listDeviceTypes = useCallback(async () => {
    const q = isDevelopment ? query(collection(firestore, `deviceTypes`)) : query(collection(firestore, `deviceTypes`), where('isPublic', '==', true))
    const snap = await getDocs(q)

    const deviceTypes = snap.docs.map((s) => getData<DeviceType>(s))
    return deviceTypes
  }, [])

  const getDeviceType = useCallback(async (id: string) => {
    const deviceRef = doc(firestore, `deviceTypes/${id}`)
    const snap = await getDoc(deviceRef)
    if (!snap.exists() || !snap.data()) {
      throw new NotFoundError('deviceType', id)
    }
    if (!snap.data().isPublic) {
      throw new ValidationError(`deviceType has not been published: ${id}`)
    }
    return getData<DeviceType>(snap)
  }, [])

  return { listDeviceTypes, getDeviceType }
}
